<template>
	<div class="login-body">
        <Toast :breakpoints="{'920px': {width: '100%', right: '0', left: '0'}}" />
		<div class="login-wrapper">
			<div class="login-panel">
				<a @click="goDashboard" class="mb-2">
                    <img src="layout/images/site_logo_2.png" alt="freya-layout" />
                </a>
                <InputText v-model="email_str" id="email" placeholder="Email" />
                <Password v-model="password_str" id="password" placeholder="Password" :feedback="false" />
                <Button label="LOGIN" type="button" @click="onLogin"></Button>
                <!-- <a href="#">forget password?</a>
                <p>Don’t you have an account, <a href="#">sign up</a></p> -->
            </div>
        </div>
        <!-- <div class="login-footer">
			<h4>freya</h4>
            <h6>Copyright Ⓒ PrimeTek Informatics</h6>
        </div> -->
    </div>
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            email_str: null,
            password_str: null
        }
    },
    methods: {
        onLogin() {
            let userinfo = {
                email: this.email_str,
                password: this.password_str
            };
            
            axios.post('https://api.tradersr.com/api/login', userinfo)
            .then(res => {
                console.log(res.data);
                localStorage.setItem('tradersr_email', res.data.email);
                localStorage.setItem('tradersr_token', res.data.token);

                this.$router.push({name:'home'});
            })
            .catch(e => {
                console.log(e);
                this.$toast.add({severity:'error', summary: 'ログイン', detail: this.email_str +' のログインに失敗しました', life: 2000});
            })
        }
    }
};
</script>